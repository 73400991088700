@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/gridDefaults.scss';

.root {
  border-top: 1px solid var(--colorGrey100);
}

.footer {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 80px;
  margin: 16px auto 0 auto;
  padding: 0 24px;

  @media (--viewportMedium) {
    gap: 30px;
    margin: 30px auto 0 auto;
    padding: 0;
  }
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.footerMobileMenuItem {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 80px;
  margin: 16px auto 0 auto;

  @media (--viewportMedium) {
    gap: 30px;
    margin: 30px auto 0 auto;
    padding: 0;
  }

  .footerMobileContent {
    display: flex;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0px;
    margin-top: 1px;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mobile {
  display: block;
  padding: 0 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    align-items: flex-start;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.sloganContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    align-items: flex-start;
  }
}

.slogan {
  font-size: 12px;
  font-style: italic;
  color: var(--colorTextBlack);
  font-weight: var(--fontWeightRegular);
}

.sloganMobileWrapper {
  padding-bottom: 5px;

  .sloganMobile {
    font-size: 12px;
    font-style: italic;
    color: var(--colorTextBlack);
    font-weight: var(--fontWeightRegular);
  }
}

.sloganImgMobile {
  max-width: 170px;
}

.sloganDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  @media (--viewportLarge) {
    justify-content: center;
  }
}

.logo {
  grid-area: logo;
}

.socialAndCopySection {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.verticalBar {
  content: '';
  display: inline-block;
  width: 1px;
  height: 12px;
  background-color: var(--colorBlack);
  margin: 0 4px;
}

.copyrightSection {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
}

.copyright {
  display: inline-block;
  font-size: 13px;
  margin-top: 24px;
  color: var(--colorBlack);
  font-weight: var(--fontWeightRegular);
  line-height: normal;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}

.socialIconsMobile {
  margin-top: 24px;
  margin-left: 4px;
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.logoImage {
  height: 24px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }

  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.hubspotSubscribeButton {
  height: 38px;
  min-height: 38px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 12px;
  width: fit-content;
}

.hubspotSubscribeWrapperMobile {
  margin: 30px auto 10px auto;
  .hubspotSubscribeButtonMobile {
    height: 38px;
    min-height: 38px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0 12px;
    width: fit-content;
  }
}

.footerImgContainer {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  gap: 30px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.footerImg {
  width: 100%;
}

.sloganImg {
  max-width: 170px;
}

.footerImgContainerMobile {
  margin: 20px 0 50px 0;
  max-width: 180px;

  .footerImgMobile {
    width: 100%;
  }
}

.footerRightMenu {
  @media (--viewportMedium) {
    margin-right: -200px;
  }

  @media (--viewportLargeWithPaddings) {
    margin-right: -270px;
  }
}
