@import '../../../../styles/customMediaQueries.css';

.root {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {
    color: #16181f !important;
    font-size: 12px;
    line-height: 2em;
    font-weight: var(--fontWeightSemiBold);

    @media (--viewportLarge) {
      font-size: 13px;
      line-height: 18px;
    }

    &:hover {
      color: var(--colorBlueButton) !important;
      text-decoration: none;
    }
  }

  & ol {
    margin-top: 0px;
    padding-left: 0px;
  }

  & li {
    font-size: 16px;
    line-height: 2em;
    padding: 0px;
    list-style-position: inside;
    margin-bottom: 5px;
    color: #16181f;
    font-weight: var(--fontWeightMedium) !important;

    @media (--viewportLarge) {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  & li:last-child {
    max-width: 116px;
  }

  & p {
    padding: 0px;
  }
}
